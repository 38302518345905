/* eslint-disable @next/next/no-css-tags */
import React from 'react';
import Head from 'next/head';
import { Roboto } from '@next/font/google';
import { AppContextProvider } from '../context';
import AdminLayout from '../components/layouts/Admin';
import 'react-toastify/dist/ReactToastify.css';

const roboto = Roboto({
  subsets: ['latin-ext'],
  weight: ['400', '700'],
});

function MyApp({ Component, pageProps, err }) {
  return (
    <>
      <Head>
        <title>Admin | Habitat Coins</title>
        <link rel="stylesheet" href="/css/bootstrap.css" />
        <link rel="stylesheet" href="/css/paper-dashboard.css" />
      </Head>
      <AppContextProvider className={roboto.className}>
        <AdminLayout>
          <Component {...pageProps} err={err} />
        </AdminLayout>
      </AppContextProvider>
    </>
  );
}

export default MyApp;
