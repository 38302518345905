export const EMAIL_TYPES = {
  FORM_SUBMIT: 'form-submit',
  CHANGE_PASSWORD: 'change-password',
  NEW_ACCOUNT: 'new-account-employee',
  PRODUCT_DELIVERY: 'product-delivery',
  COINS_SUBTRACTED: 'coins-subtracted',
  COINS_APPROVED: 'coins-approved',
  COINS_REJECTED: 'coins-rejected',
  COINS_ADDED: 'coins-added',
  TRANSACTION: 'transaction',
  REDEEM_PRODUCT: 'redeem-product',
} as const;

export const USER_TYPES = {
  TEAM: 1,
  EMPLOYEE: 2,
  ALLY: 3,
} as const;
