import React from 'react';
import axios from 'axios';
import {
  signOut,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import { useRouter } from 'next/router';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Modal,
  Input,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col,
  FormGroup,
  Button,
  Label,
} from 'reactstrap';
import { AxiosResponse } from 'postmark/node_modules/axios';
import { auth } from '../lib/firebase';
import { Toast, notify } from './Toast';
import { useFirebaseUser } from '../hooks';
import { EMAIL_TYPES } from '../lib/constants';

const dropdownStyle = {
  marginRight: '75px',
};

interface HeaderProps {
  routes: Array<any>;
}

export default function Header({ routes }: HeaderProps) {
  const sidebarToggle = React.useRef<HTMLButtonElement>();
  const router = useRouter();
  const [firebaseUser] = useFirebaseUser();
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [color, setColor] = React.useState('dark');
  const [responsive, setResponsive] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [modal, setModal] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState('');
  const [password, setPassword] = React.useState('');

  const openModal = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setModal(!modal);
  };

  const toggle = () => {
    if (isOpen) {
      setColor('transparent');
    } else {
      setColor('dark');
    }
    setIsOpen(!isOpen);
  };

  const dropdownToggle = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setDropdownOpen(!dropdownOpen);
  };

  const openSidebar = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    document.documentElement.classList.toggle('nav-open');
    sidebarToggle.current.classList.toggle('toggled');
  };

  const signout = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    await signOut(auth);
    await router.push('/admin/login');
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined' && window.screen.width < 992) {
      setColor('transparent');
      setResponsive(true);
    }
  }, []);

  const buttonStyle = {
    color: 'white',
    paddingRight: '0',
  };

  const settingsStyle = {
    justifyContent: 'flex-end',
    // display: 'flex'
  };
  const gearResponsive = {
    marginLeft: '135px',
  };

  const handleNewPassword = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      e.preventDefault();
      setModal(!modal);
      if (newPassword.length < 8 || password.length < 8) {
        return notify('La contraseña debe tener minimo 8 caracteres');
      }
      await updatePassword(firebaseUser, newPassword);
      await reauthenticateWithCredential(
        firebaseUser,
        EmailAuthProvider.credential(email, newPassword)
      );
      notify('Nueva contraseña actualizada', 'success');
      await axios.post<any, AxiosResponse<any>>('/api/send-email', {
        template: EMAIL_TYPES.CHANGE_PASSWORD,
        email: firebaseUser.email ?? '',
      });
    } catch (err) {
      console.log(err);
      notify('Ha ocurrido un error');
    }
  };

  return (
    <Navbar color={color} expand="lg" className="navbar-absolute fixed-top">
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              className="navbar-toggler"
              ref={sidebarToggle}
              onClick={openSidebar}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
        </div>
        <NavbarToggler onClick={toggle}>
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          <Nav style={responsive ? settingsStyle : null}>
            <Dropdown
              isOpen={dropdownOpen}
              toggle={dropdownToggle}
              style={responsive ? null : dropdownStyle}
            >
              <DropdownToggle caret nav style={gearResponsive}>
                <i className="nc-icon nc-settings-gear-65" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={openModal}
                  style={responsive ? buttonStyle : null}
                >
                  Cambiar Contraseña
                </DropdownItem>
                <DropdownItem
                  onClick={signout}
                  style={responsive ? buttonStyle : null}
                >
                  Cerrar Sesión
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </Collapse>
      </Container>
      <Modal isOpen={modal} toggle={openModal} centered>
        <ModalHeader toggle={openModal}>Cambiar Contraseña</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Correo Electronico</Label>
                  <Input
                    type="text"
                    name="select"
                    value={email}
                    disabled
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Contraseña Actual</Label>
                  <Input
                    placeholder="Contraseña"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row />
            <Row>
              <Col>
                <FormGroup>
                  <Label>Nueva Contraseña</Label>
                  <Input
                    placeholder="Nueva Contraseña"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <i className="small">(Minimo 8 caracteres)</i>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggle}>
            Cancelar
          </Button>
          <Button
            disabled={newPassword.length < 8}
            onClick={handleNewPassword}
            className="ml-2"
            color="success"
          >
            Aceptar
          </Button>{' '}
        </ModalFooter>
      </Modal>
      <Toast />
    </Navbar>
  );
}
