import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

export default function Home() {
  const router = useRouter();

  React.useEffect(() => {
    router.push('/admin/users');
  }, []);

  return (
    <div className="container">
      <Head>
        <title>Habitat Venezuela</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
    </div>
  );
}
