import React from 'react';
import Link from 'next/link';
import { Nav } from 'reactstrap';
import { User } from '../models';
// javascript plugin used to create scrollbars on windows

let ps;

interface SidebarProps {
  bgColor: string;
  activeColor: string;
  nameStore: string;
  routes: Array<{
    path: string;
    name: string;
    icon: string;
    layout: string;
    color: string;
  }>;
  currentUser: User;
}

export default function Sidebar({
  bgColor,
  activeColor,
  nameStore,
  routes,
  currentUser,
}: SidebarProps) {
  return (
    <div
      className="sidebar"
      data-color={bgColor}
      data-active-color={activeColor}
    >
      <div className="logo text-center">
        <span className="simple-text logo-normal">
          {currentUser?.status
            ? nameStore || currentUser?.name
            : 'HABITAT VENEZUELA'}
        </span>
      </div>
      <div className="sidebar-wrapper">
        <Nav>
          {routes.map((route) => {
            if (
              route.layout === '/selling_points' &&
              currentUser?.status === 3
            ) {
              return (
                <li key={route.path}>
                  <Link
                    href={`${route.layout}${route.path}`}
                    className="nav-link"
                  >
                    <i
                      className={`now-ui-icons ${route.icon} text-${route.color}`}
                    />
                    <p>{route.name}</p>
                  </Link>
                </li>
              );
            }
            if (route.layout === '/admin' && currentUser?.status !== 3) {
              return (
                <li key={route.path}>
                  <Link
                    href={`${route.layout}${route.path}`}
                    className="nav-link"
                  >
                    <i
                      className={`now-ui-icons ${route.icon} text-${route.color}`}
                    />
                    <p>{route.name}</p>
                  </Link>
                </li>
              );
            }
            return null;
          })}
        </Nav>
      </div>
    </div>
  );
}
