/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useRouter } from 'next/router';
import { useUser } from '../../hooks';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import routes from '../routes';

let ps;

interface DashboardProps {
  children: React.ReactNode;
  rest?: any;
}

export default function AdminLayout({ children, ...props }: DashboardProps) {
  const router = useRouter();
  const [user] = useUser();
  const [backgroundColor] = React.useState('black');
  const [activeColor] = React.useState('info');
  const mainPanel = React.useRef<HTMLDivElement>(null);
  const [nameStore, setNameStore] = React.useState('');

  React.useEffect(() => {
    if (router.query.name) {
      setNameStore(
        typeof router?.query?.name === 'string'
          ? router?.query?.name ?? ''
          : router?.query?.name?.[0] ?? ''
      );
    }
  }, [router?.query?.name]);

  if (router.asPath === '/admin/login') {
    return <>{children}</>;
  }

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        routes={routes}
        bgColor={backgroundColor}
        activeColor={activeColor}
        currentUser={user}
        nameStore={nameStore}
      />
      <div className="main-panel" ref={mainPanel}>
        <Navbar {...props} routes={routes} />
        {children}
      </div>
    </div>
  );
}
