const routes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: "nc-icon nc-bank",
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-diamond",
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   layout: "/admin"
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   layout: "/admin"
  // },
  // {
  //   path: "/user-page",
  //   name: "User Profile",
  //   icon: "nc-icon nc-single-02",
  //   layout: "/admin"
  // },
  // {
  //   path: "/tables",
  //   name: "Table List",
  //   icon: "nc-icon nc-tile-56",
  //   layout: "/admin"
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "nc-icon nc-caps-small",
  //   layout: "/admin"
  // },
  // {
  //   pro: true,
  //   path: "/upgrade",
  //   name: "Upgrade to PRO",
  //   icon: "nc-icon nc-spaceship",
  //   layout: "/admin"
  // },
  {
    path: '/users',
    name: 'Usuarios',
    icon: 'nc-icon nc-single-02',
    layout: '/admin',
    color: 'success',
  },
  {
    path: '/clients',
    name: 'Clientes',
    icon: 'nc-icon nc-badge',
    layout: '/admin',
    color: 'info',
  },
  {
    path: '/habitat_team',
    name: 'Equipo Habitat',
    icon: 'nc-icon nc-image',
    layout: '/admin',
    color: 'success',
  },
  {
    path: '/employees',
    name: 'Vendedores',
    icon: 'nc-icon nc-image',
    layout: '/admin',
    color: 'warning',
  },
  {
    path: '/products',
    name: 'Productos',
    icon: 'nc-icon nc-money-coins',
    layout: '/admin',
    color: 'danger',
  },
  {
    path: '/selling_points',
    name: 'Alidados del Market Place',
    icon: 'nc-icon nc-mobile',
    layout: '/admin',
    color: 'success',
  },
  {
    path: '/requests',
    name: 'Solicitudes de Habitat C.',
    icon: 'nc-icon nc-single-copy-04',
    layout: '/admin',
    color: 'info',
  },
  {
    path: '/products_habitat',
    name: 'Productos Habitat',
    icon: 'nc-icon nc-tile-56',
    layout: '/admin',
    color: 'warning',
  },
  {
    path: '/exchanges',
    name: 'Canjeos',
    icon: 'nc-icon nc-cart-simple',
    layout: '/admin',
    color: 'danger',
  },
  {
    path: '/emails',
    name: 'Correos masivos',
    icon: 'nc-icon nc-email-85',
    layout: '/admin',
    color: 'warning',
  },
  {
    path: '/maintenance',
    name: 'Mantenimiento',
    icon: 'nc-icon nc-settings',
    layout: '/admin',
    color: 'success',
  },
  // {
  //   path: "/requests",
  //   name: "Solicitudes de clientes",
  //   icon: "nc-icon nc-tile-56",
  //   layout: "/admin"
  // }
  {
    path: '/history',
    name: 'Historial',
    icon: 'nc-icon nc-single-copy-04',
    layout: '/selling_points',
    color: 'warning',
  },
  {
    path: '/point_of_sell',
    name: 'Punto de Venta',
    icon: 'nc-icon nc-money-coins',
    layout: '/selling_points',
    color: 'danger',
  },
  {
    path: '/confirm_delivery',
    name: 'Confirmar Entrega',
    icon: 'nc-icon nc-tile-56',
    layout: '/selling_points',
    color: 'info',
  },
  {
    path: '/edit',
    name: 'Editar Nombre',
    icon: 'nc-icon nc-badge',
    layout: '/selling_points',
    color: 'success',
  },
];

export default routes;
