import React from 'react';
import { ToastContainer, toast } from 'react-toastify';

export function Toast() {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}

export const notify = (
  msg: React.ReactNode,
  success: boolean | string = false
) => {
  const config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };
  if (success) {
    toast.success(msg, config);
  } else {
    toast.error(msg, config);
  }
};
